import type { ActionsToActionGroupsMap } from './types';

export const ACTIONS_TO_ACTION_GROUPS_MAP: ActionsToActionGroupsMap = {
  settings: {
    userManagement: {
      view: ['hdp.admin'],
    },
  },
  accountMenu: {
    dataStorageDisplay: {
      view: ['hdp.admin'],
    },
  },
};
