import type { ApiConfig } from './types';

export const API_ENDPOINTS: ApiConfig = {
  auth: {
    redirectUriPath: '/api/auth/login',
    logout: '/api/auth/logout',
    sessionContext: '/api/auth/session-context',
    sessionInfo: '/api/auth/session-info',
    tenantSwitch: '/api/auth/tenant/__TENANT_ID__',
    userConsent: '/api/auth/user/consent',
  },
  feed: '/api/feed/api/v1/pilet',
  proxy: '/api/service/v1',
  support: {
    customer_support: '/api/support/customer-support',
  },
  techEndpoint: '/ai-proxy/techeventlog',
  tenant: {
    licenses: '/api/tenant/licenses',
    storageInfo: '/api/tenant/storage-info',
    info: '/api/tenant/info',
    presets: '/api/tenant/presets',
    users: '/api/tenant/users',
    user: '/api/tenant/users/__USER_ID__',
    userLicenses: '/api/tenant/users/__USER_ID__/licenses',
    userPresets: '/api/tenant/users/__USER_ID__/presets',
  },
  termsAndConditions: '/api/legal/terms-and-conditions',
};
