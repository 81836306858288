import type { PiralPlugin } from 'piral-core';
import type { ReadonlyDeep } from 'type-fest';

import details from '../../details.codegen';
import type { VersionInfo } from '../../types';

/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends PiletVersionsApi {}
}

export interface PiletVersionsApi {
  /**
   * Register a software product with the product version (including build number) which is displayed in the Software Version dialog. Ensure to exclude any other software version (such as APIs, libraries, or external dependencies) from the registration.
   * @param name Name of the Product. Example: 'HDP App Shell'.
   * @param version Version of the Product, including build number. Example: '4.1.0-20241007.12'.
   */
  addVersionInfo(name: string, version: string): void;

  /**
   * Provides a list of all added software versions.
   */
  getVersionInfo(): ReadonlyDeep<Array<VersionInfo>>;

  /**
   * Returns the version of the HDP App Shell API which comprises all frontend API provided to pilets by the App Shell. The version number is in the format "{major}.{minor}".
   */
  getApiVersion(): string;
}

function sortVersions(
  versions: Array<VersionInfo>,
  locale: string
): ReadonlyDeep<Array<VersionInfo>> {
  const collator = new Intl.Collator(locale);

  return [...versions].sort((a: VersionInfo, b: VersionInfo) => collator.compare(a.name, b.name));
}

export function createVersionsApi(): PiralPlugin<PiletVersionsApi> {
  const versionInfo: Array<VersionInfo> = [];

  // Log needed by requirement:
  console.log('HDP App Shell API version:', details.apiVersion);

  return () => (api) => ({
    addVersionInfo(name, version) {
      versionInfo.push({ name, version });
    },
    getVersionInfo() {
      return sortVersions(versionInfo, api.getBrowserLocale());
    },
    getApiVersion() {
      return details.apiVersion;
    },
  });
}
