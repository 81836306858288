import { registerTypefaces } from '@zeiss/zui';

import { API_ENDPOINTS } from './api-endpoints';
import { createLayout } from './app-layout';
import {
  fetchAndStoreSessionContext,
  fetchAndStoreSessionData,
  userShouldAuthenticate,
  zeissIdLogin,
} from './auth';
import { setCsrfForFetch, setCsrfForXHR } from './auth/csrf-interceptors';
import { installConsentScript } from './consent';
import { createApp } from './create-app';
import { getPublicPath } from './environment';
import { renderApp } from './render';
import { setupTracking } from './tracking/init';
import type { AppConfig, RuntimeConfig, UserDevice } from './types';
import { resolveClientType } from './utils/client';
import { initFetchJSON } from './utils/fetch-json';
import { initTechLogger } from './utils/logging';
import { redirectPubliclyAccessibleRoutes } from './utils/public-pages';
import { startSessionRefreshStream } from './utils/session-refresh';

export async function setupApp(appConfig: AppConfig, deviceType: UserDevice): Promise<void> {
  const config: RuntimeConfig = {
    ...appConfig,
    proxy: API_ENDPOINTS.proxy,
    endpoints: API_ENDPOINTS,
  };

  await registerTypefaces(`${getPublicPath()}assets/fonts`);

  setCsrfForFetch(config.auth.csrfCookieName, config.auth.csrfHeaderName);
  setCsrfForXHR(config.auth.csrfCookieName, config.auth.csrfHeaderName);

  initFetchJSON(config);
  initTechLogger(config);
  document.title = config.title;

  const sessionInfo = await fetchAndStoreSessionData(config);
  await fetchAndStoreSessionContext(config);

  if (sessionInfo) {
    startSessionRefreshStream(config, sessionInfo.sessionExpirationInSeconds);
  }

  const clientType = resolveClientType(deviceType);

  redirectPubliclyAccessibleRoutes(clientType);

  if (userShouldAuthenticate()) {
    await zeissIdLogin(config);
    return;
  }

  installConsentScript(config.oneTrust.id);

  if (config.tracking.enabled) {
    setupTracking(config);
  }

  // The following code is only reached initially if user is authenticated (the page will be refreshed otherwise)
  const app = createApp(config);
  const layout = await createLayout(app, clientType);

  renderApp(app, layout);
  document.getElementById('loading-container')?.remove();
}
