import 'core-js/proposals/reflect-metadata';
import 'zone.js';

declare global {
  interface Window {
    ng: any;
  }
}

const ngs: Record<string, any> = {};

const dependencies: Record<string, string> = {};

// Reading the stack of an Error object, to determine the caller context in runtime
// e.g.: https://stg.mayer.hdp-cicd.zeiss.com/feed/files/mock-app/@team/pilet-name/3.0.0/angular-common.js
function getUrl(): string {
  const { stack } = new Error();
  const lines = stack?.split('\n') || [];

  if (lines[0] === 'Error') {
    // V8
    const line = lines[3] || '';
    return /\((.*):\d+:\d+\)$/.exec(line)?.[1] || '';
  }

  // SpiderMonkey and JavaScriptCore
  const line = lines[2] || '';
  return /@(.*):\d+:\d+$/.exec(line)?.[1] || '';
}

// Find version for a given url from dependency map or return app shell's version
function getNgVersion(url: string): string | undefined {
  const dependency = dependencies[url];

  if (dependency) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, version] = dependency.substring(1).split('@');
    return version;
  }

  return undefined;
}

// Store all available dependencies and their URL internally for later use, called during render
export function updateDependencies(items?: Record<string, string>): void {
  if (items) {
    Object.entries(items).forEach(([name, url]) => {
      dependencies[url] = name;
    });
  }
}

/*
  In Angular window.ng is a reserved, internal place to store angular-compiler for JIT compiled apps.
  This is always overridden by the latest version which is loaded...

  To avoid this behavior as a side effect in the getter/setter, dynamic value need to be read regarding
  the runtime context, by the caller's url.
*/
Object.defineProperty(window, 'ng', {
  get() {
    const url = getUrl();
    const version = getNgVersion(url);
    return version ? ngs[version] : undefined;
  },
  set(value) {
    const url = getUrl();
    const version = getNgVersion(url);

    if (version) {
      ngs[version] = value;
    }
  },
  configurable: true,
});
