import { StatusCodes } from 'http-status-codes';

import { endSession } from '../auth/logout';
import { ErrorCodeResponseError, RequestError } from '../error-handling/types';
import type { RuntimeConfig } from '../types';
import { getSessionStorageItem, StorageKey } from './storage';

let runtimeConfig: RuntimeConfig;

export function initFetchJSON(config: RuntimeConfig): void {
  runtimeConfig = config;
}

/**
 * Execute original fetch function and return extracted json data of response.
 * Additionally logs failed responses as exceptions.
 *
 * @param args Arguments that should be provided to original fetch function
 * @returns json data of response payload
 * @throws Error with response info in case response status is outside range 200-299
 */
export function fetchJSON<T>(...args: [RequestInfo, RequestInit?]): Promise<T> {
  return fetch(...args)
    .then((response) => {
      if (
        response.status === StatusCodes.UNAUTHORIZED &&
        !response.url.includes(runtimeConfig.endpoints.auth.logout) &&
        getSessionStorageItem(StorageKey.SESSION_DATA)
      ) {
        endSession(runtimeConfig);
      }

      return response;
    })
    .then((response) => {
      if (response.ok) {
        return response.status === StatusCodes.NO_CONTENT ? Promise.resolve('') : response.json();
      }

      return response.text().then((body) => {
        let bodyJson;
        try {
          bodyJson = body ? JSON.parse(body) : undefined;
        } catch (err: unknown) {
          // no-op, response text was not json
        }

        if (bodyJson) {
          if ('errorCode' in bodyJson) {
            throw new ErrorCodeResponseError(bodyJson.errorCode);
          }

          if ('source' in bodyJson) {
            throw new RequestError(response.status, response.statusText, bodyJson.source);
          }
        }

        throw new RequestError(response.status, response.statusText);
      });
    });
}
