import type { ClientSession, SessionContext } from '@arnold/common/types';

import {
  clearSessionStorage,
  getLocalStorageItem,
  getSessionStorageItem,
  removeLocalStorageItem,
  removeSessionStorageItem,
  setLocalStorageItem,
  setSessionStorageItem,
  StorageKey,
} from '../utils/storage';
import { reloadPageWhenWindowFocused } from './reload-handler';

const userChannel = new BroadcastChannel('mayer:user');

export const removeSessionData = (): void => {
  removeSessionStorageItem(StorageKey.SESSION_DATA);
  userChannel.postMessage(null);
};

export const resetStorageData = (): void => {
  clearSessionStorage();
  userChannel.postMessage(null);
};

export const addSessionData = (data: ClientSession): void => {
  setSessionStorageItem(StorageKey.SESSION_DATA, JSON.stringify(data));
  userChannel.postMessage(data);
};

export const addSessionContext = (data: SessionContext): void => {
  setSessionStorageItem(StorageKey.SESSION_CONTEXT, JSON.stringify(data));
};

export const registerSessionSync = (): void => {
  userChannel.onmessage = (ev: MessageEvent<null | ClientSession>) => {
    const userData = ev.data;
    if (userData === null && getSessionStorageItem(StorageKey.SESSION_DATA)) {
      removeSessionStorageItem(StorageKey.SESSION_DATA);
      reloadPageWhenWindowFocused();
    }

    if (userData && getSessionStorageItem(StorageKey.SESSION_DATA) === null) {
      setSessionStorageItem(StorageKey.SESSION_DATA, JSON.stringify(userData));
      location.reload();
    }
  };
};

export const saveActiveTenantId = (activeTenantId: number | undefined): void => {
  if (activeTenantId) {
    setLocalStorageItem(StorageKey.ACTIVE_TENANT_ID, activeTenantId?.toString());
  } else {
    removeLocalStorageItem(StorageKey.ACTIVE_TENANT_ID);
  }
};

export const getActiveTenantId = (): number | undefined =>
  Number(getLocalStorageItem(StorageKey.ACTIVE_TENANT_ID)) || undefined;
