import type { UserDevice } from '../types';
import { getLocalStorageItem, StorageKey } from './storage';

const MEDIA_QUERY_STANDALONE = '(display-mode: standalone)';

export enum ClientType {
  Desktop = 'desktop',
  MobileBrowser = 'mobile-browser',
  MobilePwa = 'mobile-pwa',
}

export const resolveClientType = (deviceType: UserDevice): ClientType => {
  const layoutOverride = getPersistedTypeIfExists();

  if (layoutOverride) {
    return layoutOverride;
  }

  if (isPwa() && deviceType === 'mobile') {
    return ClientType.MobilePwa;
  }

  if (deviceType === 'mobile') {
    return ClientType.MobileBrowser;
  }

  return ClientType.Desktop;
};

const getPersistedTypeIfExists = (): ClientType | undefined => {
  const typeOverride = getLocalStorageItem(StorageKey.CLIENT_TYPE);

  if (
    typeOverride === ClientType.Desktop ||
    typeOverride === ClientType.MobilePwa ||
    typeOverride === ClientType.MobileBrowser
  ) {
    return typeOverride;
  }

  return undefined;
};

const isPwa = (): boolean => window.matchMedia(MEDIA_QUERY_STANDALONE).matches;
