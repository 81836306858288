import type { SessionContext } from '@arnold/common';
import type { ClientSession, User } from '@arnold/common/types';

import type { RuntimeConfig } from '../types';
import { fetchJSON } from '../utils/fetch-json';
import { getSessionStorageItem, setSessionStorageItem, StorageKey } from '../utils/storage';
import { ACTIONS_TO_ACTION_GROUPS_MAP } from './constants';
import {
  addSessionContext,
  addSessionData,
  removeSessionData,
  saveActiveTenantId,
} from './session.provider';
import type { ActionGroupsSelector } from './types';

export function getUserData(): User | undefined {
  return getSessionDataFromStorage()?.user;
}

export function getSessionData(): ClientSession | undefined {
  return getSessionDataFromStorage();
}

export function getSessionContext(): SessionContext | undefined {
  return getSessionContextFromStorage();
}

export function fetchAndStoreSessionData(config: RuntimeConfig): Promise<ClientSession | void> {
  return fetchJSON<ClientSession>(config.endpoints.auth.sessionInfo)
    .then((userData) => {
      addSessionData(userData);
      saveActiveTenantId(userData.activeTenantId);
      return userData;
    })
    .catch(() => {
      removeSessionData();
    });
}

export async function fetchAndStoreSessionContext(config: RuntimeConfig): Promise<void> {
  const existingSessionContext = getSessionContextFromStorage();

  if (existingSessionContext) {
    return Promise.resolve();
  }

  try {
    const sessionContext = await fetchJSON<SessionContext>(config.endpoints.auth.sessionContext);
    addSessionContext(sessionContext);
  } catch (error) {
    console.error('Failed to fetch session context', error);
  }
}

export function getAcceptedTermsAndConditions(
  termsAndConditionsUrl: string,
  language: string
): Promise<string> {
  const languageQueryParam = new URLSearchParams({ language }).toString();
  const url = `${termsAndConditionsUrl}/?${languageQueryParam}`;

  return fetch(url).then((res) => res.text());
}

function getSessionDataFromStorage(): ClientSession | undefined {
  const rawData = getSessionStorageItem(StorageKey.SESSION_DATA) || '';
  try {
    return JSON.parse(rawData);
  } catch (error) {
    return undefined;
  }
}

function getSessionContextFromStorage(): SessionContext | undefined {
  const rawData = getSessionStorageItem(StorageKey.SESSION_CONTEXT) || '';
  try {
    return JSON.parse(rawData);
  } catch (error) {
    return undefined;
  }
}

export function updateSessionContextInStorage(data: Partial<SessionContext>): void {
  const newData = {
    ...getSessionContextFromStorage(),
    ...data,
  };

  setSessionStorageItem(StorageKey.SESSION_CONTEXT, JSON.stringify(newData));
}

export function hasUserAccessTo(actionGroupsSelector: ActionGroupsSelector): boolean {
  const actionGroups = actionGroupsSelector(ACTIONS_TO_ACTION_GROUPS_MAP);
  const userActionGroups = getSessionData()?.actionGroups || [];

  return actionGroups.some((actionGroup) => userActionGroups.includes(actionGroup));
}
